<template>
<div class="s-frame">
    <a-layout>
        <a-layout-content>
            <a-row class="s-layout" style="padding-left:0;padding-right:0">
                <a-col :span="24" style="display:flex">
                    <div class="s-sidebar-div">
                        <div class="s-s-font1" style="margin-bottom:14px">상위노출 제품분석</div>
                        <div class="s-s-font2" style="margin-bottom:14px" v-if="skeyword">키워드 : <span>{{skeyword}}</span></div>

                        <a-input class="s-f-input1" v-model="keyword" @pressEnter="onSearch()" placeholder="키워드입력">
                            <div slot="suffix">
                                <img src="@/assets/images/v4-icon/search.png" style="cursor:pointer;" v-on:click="onSearch()" />
                            </div>
                        </a-input>

                        <div style="padding-top:40px">
                            <a-radio :default-checked="true" v-model="is40" @change="onChange40">
                                40순위까지 불러오기
                            </a-radio>
                            <br />
                            <a-radio style="margin-top:10px" v-model="is100" @change="onChange100" :disabled="level<1">
                                100순위까지 불러오기
                            </a-radio>
                            <a-radio style="margin-top:10px" v-model="is200" @change="onChange200" :disabled="level<1">
                                200순위까지 불러오기
                            </a-radio>
                        </div>
                    </div>
                    <div class="s-contents-div" style="overflow-y:scroll">
                        <div style="margin:0 auto;text-algin:center; max-width: 1150px;">
                            <div class="s-s-font2" style="margin-bottom:38px">키워드 : <span>{{skeyword}}</span></div>
                            <div class="s-s-font3" style="margin-bottom:15px">상위노출 제품 판매요약</div>
                            <div class="" style="margin-bottom:47px">해당 키워드에 대한 상위노출 제품에 대한 분석 데이터입니다.</div>
                            <a-row>
                                <a-col :span="12">
                                    <div class="s-s-font5" style="margin-bottom:10px">1page 상위 10개 제품 1개월 평균 예측치</div>
                                    <a-row class="s-s-div3" style="margin-right:10px">
                                        <a-col :span="9" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        월평균 판매개수
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthPurchase10).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                        <a-col :span="7" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/price-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        평균가격
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthPrice10).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                        <a-col :span="8" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        월평균 매출
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthSales10).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                                <a-col :span="12">
                                    <div class="s-s-font5" style="margin-left:10px;margin-bottom:10px">1page 전체 제품 1개월 평균 예측치</div>
                                    <a-row class="s-s-div3" style="margin-left:10px">
                                        <a-col :span="7" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/count-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        월평균 판매개수
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthPurchase40).toLocaleString()}}<span>건</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                        <a-col :span="7" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/price-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        평균가격
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthPrice40).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                        <a-col :span="9" style="display:flex;">
                                            <div class="s-s-div3-1">
                                                <div>
                                                    <img src="@/assets/images/v4-icon/sales-btn.svg">
                                                </div>
                                                <div class="s-s-col1">
                                                    <div>
                                                        매출
                                                    </div>
                                                    <div>
                                                        {{parseInt(avgMonthSales40).toLocaleString()}}<span>원</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a-col>
                                    </a-row>
                                </a-col>
                            </a-row>

                            <!-- <a-row>
                                <div style="margin-top:35px;text-align:center;">
                                    <img style="width=432px" src="@/assets/images/v4-icon/s-f-img2.png" />
                                </div>
                            </a-row> -->
                            <div style="display:block;width:100%;padding-top:60px;padding-bottom:20px">
                                <div class="s-r-btn">
                                    <a-radio-group default-value="total" button-style="solid" @change="typeChange">
                                        <a-radio-button value="total" style="width:100px;height: 40px;">전체</a-radio-button>
                                        <a-radio-button value="checkout" style="width:100px;height: 40px;">네이버페이</a-radio-button>
                                    </a-radio-group>
                                </div>
                                <div style="margin-top:30px;">
                                    <div class="tbltitle">
                                        상위제품 판매분석
                                    </div>

                                    <div class="s-btn-tab" style="display:flex;float:right;margin-bottom:21px;margin-top:-21px">
                                        <!-- 엑셀 다운로드 -->
                                        <export-excel :prefix="prefix" :header="columns" :data="tableData"></export-excel>

                                        <!-- 전체 데이터 보기 -->

                                        <div class="s-btn s-btn-type2" @click="onShowModal()">
                                            <img class="s-f-filter-default" src="@/assets/images/v4-icon/presentation.svg" />전체 데이터 보기
                                        </div>
                                        <upload-excel-component v-if="getIncludeKeyword=='T'" class="s-btn s-btn-type2" style="padding-left:5px;cursor:pointer" mode="large" :on-success="handleSuccess" />
                                    </div>
                                </div>
                            </div>

                            <div class="rank" style="">
                                <a-table class="s-f-tbl2 scrollable-tbl" :pagination="false" :columns="columns" :data-source="tableData" :scroll="{ x: 1220,y: getHeight }" rowKey="rank">
                                    <div slot="rank" slot-scope="text" style="text-align:right">
                                        {{ parseInt(text).toLocaleString() }}
                                    </div>
                                    <div slot="productName" slot-scope="record" style="text-align:right">
                                        <a :href="record.productRef" target="_blank">
                                            <div style="display:flex">
                                                <div><img class="s-r-prod-img" :src="record.imageUrl" /></div>
                                                <div class="twoline s-s-col2">{{ record.productName }}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <div slot="price" slot-scope="record">
                                        {{parseInt(record.price).toLocaleString()}}
                                    </div>
                                    <div slot="reviewCount" slot-scope="text, record">
                                        <!-- {{record.price}} -->
                                        {{parseInt(record.reviewCountStr).toLocaleString()}}
                                    </div>
                                    <div slot="keepCnt" slot-scope="text,record">
                                        {{parseInt(record.keepCntStr).toLocaleString()}}
                                    </div>
                                    <div slot="purchaseCntMonthOrg" slot-scope="text,record">
                                        <span v-if="record.purchaseCntMonthOrg>0"> {{record.purchaseCntMonth}}</span>
                                        <span v-if="record.purchaseCntMonth==''"> {{record.purchaseCntMonth}}</span>
                                        <span v-if="record.purchaseCntMonthOrg==0&& record.purchaseCntMonth!=''">
                                            <a-tag color="#108ee9" style="cursor:pointer" @click="onViewBundlePurchaseCnt()">
                                                묶음제품보기
                                            </a-tag>
                                        </span>
                                    </div>
                                    <div slot="openDateOrg" slot-scope="text,record">
                                        {{record.openDate}}
                                    </div>
                                    <div slot="mallGradeOrg" slot-scope="text,record">
                                        {{record.mallGrade}}
                                    </div>
                                    <div slot="scoreInfoOrg" slot-scope="text,record">
                                        {{record.scoreInfo}}
                                    </div>
                                    <div slot="estimatePurchaseCntMonthOrg" slot-scope="text,record">
                                        {{parseInt(record.estimatePurchaseCntMonth).toLocaleString()}}
                                    </div>
                                </a-table>
                                <div class="s-t-btn1" v-if="level<1" @click="$router.push('/pages/setting').catch(() => {})">유료 가입 하고 200순위 까지 불러오기</div>
                            </div>

                            <div style="display:block;width:100%;padding-top:60px;padding-bottom:20px">

                                <div style="margin-top:30px;">
                                    <div class="tbltitle">
                                        상위제품 키워드 한눈에 보기
                                    </div>

                                    <div class="s-btn-tab" style="display:flex;float:right;margin-bottom:21px;margin-top:-21px">
                                        <!-- 엑셀 다운로드 -->
                                        <export-excel :prefix="prefix" :header="columns" :data="tableData"></export-excel>

                                        <!-- 전체 데이터 보기 -->

                                    </div>
                                </div>
                            </div>

                            <div class="rank" style="">
                                <a-table class="s-f-tbl2 scrollable-tbl" :pagination="false" :columns="columnsTag" :data-source="tableDataTag" :scroll="{ y: getHeight }" rowKey="tag">
                                    <div slot="rank" slot-scope="text" style="text-align:right">
                                        {{ parseInt(text).toLocaleString() }}
                                    </div>
                                    <div slot="productName" slot-scope="record" style="text-align:right">
                                        <a :href="record.productRef" target="_blank">
                                            <div style="display:flex">
                                                <div><img class="s-r-prod-img" :src="record.imageUrl" /></div>
                                                <div class="twoline s-s-col2">{{ record.productName }}</div>
                                            </div>
                                        </a>
                                    </div>

                                    <div slot="titleTag" slot-scope="record" style="text-align:center">
                                        <div style="text-align:center;display: flex;flex-flow: row wrap;justify-content: center;">
                                            <div style="" v-for="(item,index) in record.titleTag" :key="index">
                                                <div style="width:100%"> <span style="padding-right:5px"><u>{{item}}</u></span></div>
                                            </div>
                                        </div>
                                    </div>

                                     <div slot="metaTag" slot-scope="record" style="text-align:center">
                                        <div style="text-align:center;display: flex;flex-flow: row wrap;justify-content: center;">
                                            <div style="" v-for="(item,index) in record.metaTag" :key="index">
                                                <div style="width:100%"> <span style="padding-right:5px"><u>{{item}}</u></span></div>
                                            </div>
                                        </div>
                                    </div>

                                </a-table>

                            </div>
                        </div>
                    </div>

                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
    <a-modal class="message" width="100vw" v-model="visible" :centered="true" :closable="false" :maskClosable="false">
        <!-- <div class="close-btn" @click="onClickClose()">닫기</div> -->
        <div class="contents">

            <a-table class="s-f-tbl2 modal" :pagination="false" :columns="columns2" :data-source="tableData" :scroll="{ x: 1800,y: getHeight }" rowKey="rank">
                <div slot="rank" slot-scope="text" style="text-align:right">
                    {{ parseInt(text).toLocaleString() }}
                </div>
                <div slot="productName" slot-scope="record" style="text-align:right">
                    <div style="display:flex">
                        <div><img class="s-r-prod-img" :src="record.imageUrl" /></div>
                        <div class="s-text-wrap s-s-col2">{{ record.productName }}</div>
                    </div>
                </div>
                <div slot="price" slot-scope="record">
                    {{parseInt(record.price).toLocaleString()}}
                </div>
                <div slot="reviewCount" slot-scope="text, record">
                    <!-- {{record.price}} -->
                    {{parseInt(record.reviewCountStr).toLocaleString()}}
                </div>
                <div slot="keepCnt" slot-scope="text,record">
                    {{parseInt(record.keepCntStr).toLocaleString()}}
                </div>
                <div slot="purchaseCntMonthOrg" slot-scope="text,record">
                    {{record.purchaseCntMonth}}
                </div>
                <div slot="openDateOrg" slot-scope="text,record">
                    {{record.openDate}}
                </div>
                <div slot="mallGradeOrg" slot-scope="text,record">
                    {{record.mallGrade}}
                </div>
                <div slot="scoreInfoOrg" slot-scope="text,record">
                    {{record.scoreInfo}}
                </div>
                <div slot="estimatePurchaseCntMonthOrg" slot-scope="text,record">
                    {{parseInt(record.estimatePurchaseCntMonth).toLocaleString()}}
                </div>
            </a-table>

        </div>
        <div class="btn-div">
            <div class="btn" @click="visible=false" style="margin-left:5px">
                닫기
            </div>
        </div>

    </a-modal>
    <message :visible="showFlag" contents="<p>회원 전용 서비스입니다.<br/>로그인 후 이용 해 주세요 😘</p>" @closed="onCloseEvent()" @clicked="onClickEvent"></message>
</div>
</template>

<script>
import {
  getCurrentTimeNew,
  getHex,
  getLicense,
  replaceAll,
  chkLicense,
  extractCategory,
  showError,
  showSuccess,
  isMobile,
  getCurrentDate4
} from "../../components/fnc.js";
import firebase from "firebase";
import ExportExcel from "../../components/ExportExcel/index";
import Message from "../../components/Message/index";
import UploadExcelComponent from "../../components/UploadExcel/index.vue";
import regression from "regression";

export default {
  components: {
    ExportExcel,
    Message,
    UploadExcelComponent
  },
  data() {
    return {
      visible: false,
      prefix: "",
      uploadedExcel: false,
      showFlag: false,
      keyword: "",
      hide: false,
      skeyword: "",
      type: "",
      level: 1,
      smode: "small",
      is40: true,
      is100: false,
      is200: false,
      avgMonthPurchase10: 0,
      avgMonthPrice10: 0,
      avgMonthSales10: 0,
      avgMonthPurchase40: 0,
      avgMonthPrice40: 0,
      avgMonthSales40: 0,
      tableData: [],
      tableDataTag: [],
      columns: [
        {
          title: "순위",
          etitle: "순위",
          width: "40px",
          key: "rank",
          align: "center",
          dataIndex: "rank",
          seq: 0
        },
        {
          title: "상품",
          etitle: "상품",
          key: "productName",
          seq: 1,
          align: "center",
          scopedSlots: {
            customRender: "productName"
          }
        },
        {
          title: "브랜드",
          etitle: "브랜드",
          key: "brand",
          align: "center",
          width: 70,
          dataIndex: "brand",
          seq: 2
        },
        {
          title: "카테고리",
          etitle: "카테고리",
          width: 70,
          key: "category",
          align: "center",
          dataIndex: "category",
          seq: 3
        },
        {
          title: "스토어",
          etitle: "스토어",
          width: 70,
          key: "name",
          align: "center",
          dataIndex: "name",
          seq: 4
        },

        {
          title: "가격",
          etitle: "가격",
          key: "price",
          width: 80,
          align: "center",
          seq: 5,
          scopedSlots: {
            customRender: "price"
          }
        },
        {
          title: "배송비",
          etitle: "배송비",
          key: "dlvryCont",
          align: "center",
          dataIndex: "dlvryCont",
          width: 80,
          seq: 6,
          className: this.hide ? "show" : "hide"
        },
        {
          title: "리뷰수",
          etitle: "리뷰수",
          key: "reviewCount",
          align: "center",
          sorter: (a, b) => a.reviewCount - b.reviewCount,
          width: 90,
          dataIndex: "reviewCount",
          seq: 7,
          scopedSlots: {
            customRender: "reviewCount"
          }
        },
        {
          title: "찜수",
          etitle: "찜수",
          key: "keepCnt",
          sorter: true,
          align: "center",
          dataIndex: "keepCnt",
          width: 80,
          sorter: (a, b) => a.keepCnt - b.keepCnt,
          seq: 8,
          scopedSlots: {
            customRender: "keepCnt"
          }
        },
        {
          etitle: "판매수(1개월)",
          key: "purchaseCntMonthOrg",
          width: 100,
          align: "center",
          sorter: (a, b) => a.purchaseCntMonthOrg - b.purchaseCntMonthOrg,
          dataIndex: "purchaseCntMonthOrg",
          seq: 9,
          scopedSlots: {
            customRender: "purchaseCntMonthOrg"
          },
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  {" "}
                  판매수 <br /> <span style="font-size:10px">
                    {" "}
                    (1 개월){" "}
                  </span>{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          etitle: "예상매출(1개월)",
          key: "estimatePurchaseCntMonthOrg",
          align: "center",
          width: 100,
          sorter: (a, b) =>
            a.estimatePurchaseCntMonthOrg - b.estimatePurchaseCntMonthOrg,
          dataIndex: "estimatePurchaseCntMonthOrg",
          seq: 10,
          scopedSlots: {
            customRender: "estimatePurchaseCntMonthOrg"
          },
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  {" "}
                  예상매출 <br /> <span style="font-size:10px">
                    (1 개월){" "}
                  </span>{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          etitle: "등록일(운영기간)",
          key: "openDateOrg",
          width: 60,
          align: "center",
          dataIndex: "openDateOrg",
          sorter: (a, b) => a.openDateOrg - b.openDateOrg,
          seq: 11,
          title: () => {
            return (
              <div style="text-align:center;">
                <span>
                  등록일 <br />
                  <span style="font-size:10px"> (운영기간) </span>{" "}
                </span>{" "}
              </div>
            );
          },
          scopedSlots: {
            customRender: "openDateOrg"
          }
        },
        {
          etitle: "셀러등급",
          title: "셀러등급",
          width: 80,
          sorter: (a, b) => a.mallGradeOrg - b.mallGradeOrg,
          key: "mallGradeOrg",
          align: "center",
          dataIndex: "mallGradeOrg",
          seq: 12,
          scopedSlots: {
            customRender: "mallGradeOrg"
          }
        },
        {
          etitle: "평점",
          title: "평점",
          width: 80,
          seq: 13,
          sorter: (a, b) => a.scoreInfoOrg - b.scoreInfoOrg,
          key: "scoreInfoOrg",
          align: "center",
          dataIndex: "scoreInfoOrg",
          scopedSlots: {
            customRender: "scoreInfoOrg"
          }
        }
      ],
      columns2: [
        {
          title: "순위",
          etitle: "순위",
          width: "40px",
          key: "rank",
          align: "center",
          dataIndex: "rank",
          seq: 0
        },
        {
          title: "상품",
          etitle: "상품",
          key: "productName",
          seq: 1,
          width: 270,
          align: "center",
          scopedSlots: {
            customRender: "productName"
          }
        },
        {
          title: "브랜드",
          etitle: "브랜드",
          key: "brand",
          align: "center",
          width: 70,
          dataIndex: "brand",
          seq: 2
        },
        {
          title: "카테고리",
          etitle: "카테고리",
          width: 120,
          key: "category",
          align: "center",
          dataIndex: "category",
          seq: 3
        },
        {
          title: "스토어",
          etitle: "스토어",
          width: 120,
          key: "name",
          align: "center",
          dataIndex: "name",
          seq: 4
        },

        {
          title: "가격",
          etitle: "가격",
          key: "price",
          width: 80,
          align: "center",
          seq: 5,
          scopedSlots: {
            customRender: "price"
          }
        },
        {
          title: "배송비",
          etitle: "배송비",
          key: "dlvryCont",
          align: "center",
          dataIndex: "dlvryCont",
          width: 80,
          seq: 6
        },
        {
          title: "리뷰수",
          etitle: "리뷰수",
          key: "reviewCount",
          align: "center",
          sorter: (a, b) => a.reviewCount - b.reviewCount,
          width: 90,
          dataIndex: "reviewCount",
          seq: 7,
          scopedSlots: {
            customRender: "reviewCount"
          }
        },
        {
          title: "찜수",
          etitle: "찜수",
          key: "keepCnt",
          sorter: true,
          align: "center",
          dataIndex: "keepCnt",
          width: 80,
          sorter: (a, b) => a.keepCnt - b.keepCnt,
          seq: 8,
          scopedSlots: {
            customRender: "keepCnt"
          }
        },
        {
          etitle: "판매수(1개월)",
          key: "purchaseCntMonthOrg",
          width: 80,
          align: "center",
          sorter: (a, b) => a.purchaseCntMonthOrg - b.purchaseCntMonthOrg,
          dataIndex: "purchaseCntMonthOrg",
          seq: 9,
          scopedSlots: {
            customRender: "purchaseCntMonthOrg"
          },
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  {" "}
                  판매수 <br /> <span style="font-size:10px">
                    {" "}
                    (1 개월){" "}
                  </span>{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          etitle: "예상매출(1개월)",
          key: "estimatePurchaseCntMonthOrg",
          align: "center",
          width: 120,
          sorter: (a, b) =>
            a.estimatePurchaseCntMonthOrg - b.estimatePurchaseCntMonthOrg,
          dataIndex: "estimatePurchaseCntMonthOrg",
          seq: 10,
          scopedSlots: {
            customRender: "estimatePurchaseCntMonthOrg"
          },
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  {" "}
                  예상매출 <br /> <span style="font-size:10px">
                    (1 개월){" "}
                  </span>{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          etitle: "등록일(운영기간)",
          key: "openDateOrg",
          width: 120,
          align: "center",
          dataIndex: "openDateOrg",
          sorter: (a, b) => a.openDateOrg - b.openDateOrg,
          seq: 11,
          title: () => {
            return (
              <div style="text-align:center;width:100%">
                <span>
                  등록일 <br />
                  <span style="font-size:10px"> (운영기간) </span>{" "}
                </span>{" "}
              </div>
            );
          },
          scopedSlots: {
            customRender: "openDateOrg"
          }
        },
        {
          etitle: "셀러등급",
          title: "셀러등급",
          width: 120,
          sorter: (a, b) => a.mallGradeOrg - b.mallGradeOrg,
          key: "mallGradeOrg",
          align: "center",
          dataIndex: "mallGradeOrg",
          seq: 12,
          scopedSlots: {
            customRender: "mallGradeOrg"
          }
        },
        {
          etitle: "평점",
          title: "평점",
          width: 80,
          seq: 13,
          sorter: (a, b) => a.scoreInfoOrg - b.scoreInfoOrg,
          key: "scoreInfoOrg",
          align: "center",
          dataIndex: "scoreInfoOrg",
          scopedSlots: {
            customRender: "scoreInfoOrg"
          }
        }
      ],
      columnsTag: [
        {
          title: "순위",
          etitle: "순위",
          width: "40px",
          key: "rank",
          align: "center",
          dataIndex: "rank",
          seq: 0
        },
        {
          title: "상품",
          etitle: "상품",
          key: "productName",
          seq: 1,
          widht: "200px",
          align: "center",
          scopedSlots: {
            customRender: "productName"
          }
        },

        {
          title: "제목태그",
          etitle: "제목태그",
          key: "titleTag",
          align: "center",
          seq: 2,
          scopedSlots: {
            customRender: "titleTag"
          }
        },
        {
          title: "메타태그",
          etitle: "메타태그",
          key: "metaTag",
          align: "center",
          seq: 3,
          scopedSlots: {
            customRender: "metaTag"
          }
        }
      ],

      loading: false
    };
  },
  computed: {
    getHeight: function() {
      return this.$vssHeight - 450;
    },
    getIncludeKeyword() {
      return this.$store.state.AppActiveUser.includekeyword;
    }
  },
  methods: {
    extractSpecialCharacter(str) {
      var _str = str.split(" ");
      var _strs = [];

      var specialChars =
        "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      _str.forEach(item => {
        var _find = false;
        for (var i = 0; i < specialChars.length; i++) {
          if (item.indexOf(specialChars[i]) > -1) {
            _find = true;
          }
        }

        if (!_find) {
          _strs.push(item);
        }
      });
      return _strs;
    },
    onViewBundlePurchaseCnt() {
      var self = this;
      var _linear = [];
      if (this.is200 == false) {
        showSuccess({
          notify: self.$vs.notify,
          msg: "200페이지 검색 후에 이용이 가능합니다."
        });
      } else {
        self.tableData.forEach(item => {
          if (item["purchaseCntMonthOrg"] == 0) {
            // console.log(item);
          } else {
            var _t = [parseInt(item.rank), parseInt(item.purchaseCntMonthOrg)];
            _linear.push(_t);
          }
        });

        const result = regression.linear(_linear, {
          precision: 0
        });
        // // y= [0]x+[1]
        const gradient = result.equation[0];
        const yIntercept = result.equation[1];

        var _swap = self.tableData;
        self.tableData = [];

        self.tableData = _swap.filter(item => {
          if (item["purchaseCntMonthOrg"] == 0) {
            item["purchaseCntMonthOrg"] =
              gradient * parseInt(item["rank"]) + yIntercept;

            item["purchaseCntMonthOrg"] =
              item["purchaseCntMonthOrg"] < 0 ? 0 : item["purchaseCntMonthOrg"];
            // console.log(parseInt(item["rank"]), item["purchaseCntMonthOrg"]);
            item["purchaseCntMonth"] =
              item["purchaseCntMonthOrg"] == 0
                ? ""
                : parseInt(
                    Math.floor(item["purchaseCntMonthOrg"])
                  ).toLocaleString() + "개";
            item["estimatePurchaseCntMonth"] =
              item["purchaseCntMonthOrg"] * item["price"];
            item["estimatePurchaseCntMonthOrg"] =
              item["purchaseCntMonthOrg"] * item["price"];
          }
          return item;
        });
        // console.log(self.tableData);
      }
    },
    onShowModal() {
      var self = this;
      if (this.tableData.length == 0) {
        showSuccess({
          notify: self.$vs.notify,
          msg: "검색후에 이용이 가능합니다."
        });
      } else {
        this.visible = true;
      }
    },
    async handleSuccess({ results, header }) {
      var self = this;
      this.tableData = [];
      this.smode = "large";

      var _swap = [];
      var _tmp = [
        {
          title: "키워드",
          width: "100px",
          key: "keyword",
          align: "center",
          dataIndex: "keyword"
        }
      ];
      _swap = [..._tmp, ...this.columns];

      this.columns = _swap;

      var _subfix = this.is40 ? "40" : this.is100 ? "100" : "200";
      this.prefix =
        getCurrentDate4() + "경젱제품분석_" + _subfix + "(스토어링크)";

      await results.forEach(async item => {
        if (item["키워드"]) {
          self.skeyword = item["키워드"];
          await self.getUrlByJson();
        }
      });
    },
    onChange40(e) {
      if (e.target.checked) {
        this.is40 = true;
        this.is100 = false;
        this.is200 = false;
      }
    },
    onChange100(e) {
      if (e.target.checked) {
        this.is40 = false;
        this.is100 = true;
        this.is200 = false;
      }
    },
    onChange200(e) {
      if (e.target.checked) {
        this.is40 = false;
        this.is100 = false;
        this.is200 = true;
      }
    },
    onClickEvent(e) {},
    onCloseEvent() {
      this.showFlag = false;
    },
    onSaveKeyword() {
      this.showFlag = true;
    },
    typeChange(e) {
      this.type = e.target.value;
      this.onSearch();
    },
    async onSearch() {
      this.tableData = [];
      this.smode = "small";

      var _subfix = this.is40 ? "40" : this.is100 ? "100" : "200";
      this.prefix =
        getCurrentDate4() +
        this.keyword +
        "_경쟁제품분석_" +
        _subfix +
        "(스토어링크)";

      this.getUrlByJson();
    },
    async getUrlByJson() {
      var _keyword = this.keyword.toUpperCase();
      _keyword = replaceAll(_keyword, " ", "");

      if (!_keyword && !this.skeyword) {
        showError({
          notify: this.$vs.notify,
          msg: "키워드 검색을 한후에 이용 부탁드립니다."
        });
        return false;
      }
      _keyword = this.keyword ? this.keyword : this.skeyword;
      this.skeyword = _keyword;
      this.keyword = "";
      this.$vs.loading();
      var type = this.type;
      var self = this;

      var mode = this.is40 ? 40 : this.is100 ? 100 : this.is200 ? 200 : 40;
      await this.$http
        .post(
          "https://asia-east2-storelink-fnc.cloudfunctions.net/getUrlByJson2",
          {
            keyword: _keyword,
            type: type,
            mode: mode
          }
        )
        .then(function(r) {
          var _category = "";
          var _dlvryCont = 0;
          var _price = 0;
          var _pricaUnit = "";
          var mallGrade = "";
          var _mallGrade = "";
          var _name = "";
          var _rank = 0;
          var _openMonth = "";
          var _productName = "";
          var _purchaseCntMonth = "";
          var rank10Cnt = 0;
          var rank40Cnt = 0;
          var total10Cnt = 0;
          var total10Price = 0;
          var total40Cnt = 0;
          var total40Price = 0;
          if (r.data.data) {
            var _data = [];

            if (r.data.data[1]) {
              _data = [...r.data.data[0], ...r.data.data[1]];
            } else {
              _data = [...r.data.data[0]];
            }

            _data.forEach(item => {
              _category = "";
              _purchaseCntMonth = 0;
              var _meta = {};
              if (item.adId) {
                //skip
              } else {
                _meta["rank"] = item.rank;
                _meta["keyword"] = item.keyword;
                _meta["productName"] = item.productTitle;
                _meta["metaTag"] = item.manuTag.split(",");

                _rank = item.rank;
                item["keyword"] = _keyword;
                item["rank"] = _rank;
                item["brand"] = item.brand ? item.brand : "";
                item["reviewCount"] = item.reviewCount ? item.reviewCount : "";
                item["reviewCountStr"] = item.reviewCount
                  ? item.reviewCount
                  : "";
                item["keepCnt"] = item.keepCnt ? item.keepCnt : "";
                item["keepCntStr"] = item.keepCnt ? item.keepCnt : "";
                // 상품
                item["productName"] = item.productTitle;

                // 카테고리

                item["category"] = extractCategory(item);

                // 셀러등급, 스토어명
                var _level = 0;
                if (item.lowMallList == null) {
                  mallGrade = item.mallInfoCache.mallGrade;
                  if (mallGrade == "M44002") {
                    _mallGrade = "프리미엄";
                    _level = 4;
                  } else if (mallGrade == "M44003") {
                    _mallGrade = "빅파워";
                    _level = 3;
                  } else if (mallGrade == "M44004") {
                    _mallGrade = "파워";
                    _level = 2;
                  } else if (mallGrade == "M44001") {
                    _mallGrade = "플래티넘";
                    _level = 1;
                  } else if (mallGrade == "M44006" || mallGrade == "") {
                    _mallGrade = "-";
                    _level = 0;
                  }

                  _name = item.mallInfoCache.name;
                } else {
                  _mallGrade = "묶음";
                  _name = "쇼핑몰별 최저가";
                  _level = 0;
                }
                item["mallGradeOrg"] = _level;
                item["mallGrade"] = _mallGrade;
                item["name"] = _name;

                // 평점
                if (item.scoreInfo != null) {
                  item["scoreInfo"] = item.scoreInfo;
                  item["scoreInfoOrg"] = parseFloat(item.scoreInfo, 1);
                } else {
                  item["scoreInfo"] = "-";
                  item["scoreInfoOrg"] = 0.0;
                }

                // 가격
                _price = item.price;
                item["productRef"] = item.crUrl ? item.crUrl : "";

                // _pricaUnit = item.priceUnit;
                // if (_pricaUnit == "KRW") {
                //   _pricaUnit = "원";
                // }
                item["price"] = item.price ? item.price : 0;

                // 배송비
                var dlvryContIdx = item.dlvryCont.indexOf("|");
                var _dlvryCont = item.dlvryCont.substr(0, dlvryContIdx);
                if (_dlvryCont == 0 || _dlvryCont == null) {
                  _dlvryCont = "무료";
                } else {
                  _dlvryCont = _dlvryCont + "원";
                }
                item["dlvryCont"] = setComma(_dlvryCont);

                // 등록일(운영기간)
                var date = new Date();
                var prodDate = item.openDate;
                var startYear = date.getFullYear();
                var startMonth = date.getMonth();
                var prodYear = parseInt(prodDate.substring(0, 4));
                var prodMonth = parseInt(prodDate.substring(4, 6));
                var openMonth =
                  (startYear - prodYear) * 12 + (startMonth - prodMonth);
                item["openDateOrg"] = item.openDate;

                item["openDate"] =
                  prodYear + "." + prodMonth + "  (" + openMonth + "개월)";

                if (openMonth > 12) {
                  item["openDate"] =
                    prodYear +
                    "." +
                    prodMonth +
                    " (" +
                    parseFloat(openMonth / 12).toFixed(1) +
                    "년)";
                } else {
                  item["openDate"] =
                    prodYear + "." + prodMonth + " (" + openMonth + "개월)";
                }

                // 1개월 판매 수
                // 오픈일이 6개월이 지나지않았다면 오픈개월 수 그대로 나누기
                var __purchaseCntMonth = 0;
                if (openMonth <= 6) {
                  if (openMonth <= 0) {
                    // 전체상품
                    _purchaseCntMonth += parseInt(item.purchaseCnt);
                    // TOP10상품
                    if (_rank <= 10 && item.purchaseCnt > 0) {
                      total10Cnt += parseInt(item.purchaseCnt);
                    }
                    // TOP40상품
                    if (_rank <= 40 && item.purchaseCnt > 0) {
                      total40Cnt += parseInt(item.purchaseCnt);
                    }
                  } else {
                    _purchaseCntMonth += parseInt(item.purchaseCnt) / openMonth;
                    if (_rank <= 10 && item.purchaseCnt > 0) {
                      total10Cnt += parseInt(item.purchaseCnt) / openMonth;
                    }
                    if (_rank <= 40 && item.purchaseCnt > 0) {
                      total40Cnt += parseInt(item.purchaseCnt) / openMonth;
                    }
                  }
                } else {
                  _purchaseCntMonth += parseInt(item.purchaseCnt) / 6;
                  if (_rank <= 10 && item.purchaseCnt > 0) {
                    total10Cnt += parseInt(item.purchaseCnt) / 6;
                  }
                  if (_rank <= 40 && item.purchaseCnt > 0) {
                    total40Cnt += parseInt(item.purchaseCnt) / 6;
                  }
                }
                __purchaseCntMonth = _purchaseCntMonth;
                _purchaseCntMonth =
                  _purchaseCntMonth != 0 || _purchaseCntMonth
                    ? Math.floor(_purchaseCntMonth) + "개"
                    : "-";
                item["purchaseCntMonthOrg"] = parseInt(__purchaseCntMonth);
                item["purchaseCntMonth"] = setComma(_purchaseCntMonth);
                item["estimatePurchaseCntMonth"] =
                  __purchaseCntMonth * item["price"];
                item["estimatePurchaseCntMonthOrg"] =
                  __purchaseCntMonth * item["price"];

                self.tableData.push(item);
                _meta["imageUrl"] = item.imageUrl;

                _meta["titleTag"] = self.extractSpecialCharacter(
                  item.productTitle
                );
                self.tableDataTag.push(_meta);

                // 1page 상위 10개 제품 1개월 평균 예측치 (left)
                if (_rank <= 10 && item.purchaseCnt > 0) {
                  rank10Cnt++;
                  // 평균가격
                  total10Price += parseInt(_price);
                  // total10Cnt = Math.floor(total10Cnt);
                  // total10Price = Math.floor(total10Price);
                }

                // 1page 상위 40개 제품 1개월 평균 예측치 (right)
                if (_rank <= 40 && item.purchaseCnt > 0) {
                  rank40Cnt++;
                  // 평균가격
                  total40Price += parseInt(_price);
                  // total40Cnt = Math.floor(total40Cnt);
                  // total40Price = Math.floor(total40Price);
                }
              }
            });
          } else {
            showSuccess({
              notify: self.$vs.notify,
              msg: "지원되지 않는 검색어 입니다."
            });
          }

          // TOP10 1개월 평균 예측치, 판매건수가 1이상인 스토어 수
          //console.log(`TOP10 판매건수가 1이상인 스토어 수 : ${rank10Cnt}`);
          // 월 평균 판매개수
          self.avgMonthPurchase10 = total10Cnt <= 0 ? "-" : total10Cnt;
          // 평균가격
          self.avgMonthPurchase10 = isNaN(self.avgMonthPurchase10)
            ? "-"
            : self.avgMonthPurchase10 / rank10Cnt;

          self.avgMonthPrice10 = isNaN(total10Price / rank10Cnt)
            ? "-"
            : total10Price / rank10Cnt;

          // 월평균 매출
          let _avgMonthSales10 = self.avgMonthPurchase10 * self.avgMonthPrice10;

          self.avgMonthSales10 = isNaN(
            self.avgMonthPurchase10 * self.avgMonthPrice10
          )
            ? "-"
            : self.avgMonthPurchase10 * self.avgMonthPrice10;

          // TOP40 1개월 평균 예측치, 판매건수가 1이상인 스토어 수
          //console.log(`TOP40 판매건수가 1이상인 스토어 수 : ${rank40Cnt}`);
          // 판매개수
          self.avgMonthPurchase40 = total40Cnt <= 0 ? "-" : total40Cnt;
          // 평균가격
          self.avgMonthPurchase40 = isNaN(self.avgMonthPurchase40)
            ? "-"
            : self.avgMonthPurchase40 / rank40Cnt;

          self.avgMonthPrice40 = isNaN(total40Price / rank40Cnt)
            ? "-"
            : total40Price / rank40Cnt;

          // 매출

          self.avgMonthSales40 = isNaN(
            self.avgMonthPurchase40 * self.avgMonthPrice40
          )
            ? "-"
            : self.avgMonthPurchase40 * self.avgMonthPrice40;

          self.$vs.loading.close();
        });
    }
  },
  mounted() {
    this.level = getLicense();
  }
};

// 콤마 추가
function setComma(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
</script>

<style>
.s-t-btn1 {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 40px;
  float: right;

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #fff;
  background: #000000;
  border-radius: 5px;
  text-align: center;
  padding-top: 9px;
  cursor: pointer;
}

/* 스토어 화면 폰트 */
.s-s-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font2 > span {
  font-weight: bold;
}

.s-s-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #333333;
}

.s-s-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #4f4f4f;
}

/* 예측치 div */
.s-s-div3 {
  height: 87px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;

  /* 예측치 font */
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: -0.03em;

  color: #000000;
}

.s-s-col1 {
  margin-top: -3px;
}

.s-s-col1 div {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  padding-left: 10px;
  color: #000000;
}

.s-s-col2 {
  text-align: left;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.03em;

  color: #4f4f4f;
  padding-left: 10px;
  padding-top: 0px;
}

/* 예측치 내부 div */
.s-s-div3-1 {
  padding: 20px;
  padding-top: 30px;
  display: flex;
}

.s-f-input1 input {
  height: 60px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #828282;
}

.s-f-input1 {
  width: 100%;
  margin-top: 40px;
}

.s-f-input1 input:hover,
.s-f-input1 input:focus {
  border: 1px solid #e0e0e0;
}

.s-r-btn .ant-radio-button-wrapper {
  background: white !important;
  color: #333333 !important;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: -0.03em;
  text-align: center;
  padding-top: 10px;
}

.s-r-btn .ant-radio-button-wrapper:hover,
.s-r-btn .ant-radio-button-wrapper:focus,
.s-r-btn .ant-radio-button-wrapper-checked {
  background: #000000 !important;
  color: #ffffff !important;
  border-color: #333333 !important;
}

/*
table
*/

.s-f-tbl2:not(.modal) .ant-table-body {
  overflow-x: hidden !important;
}

.s-f-tbl2 th {
  background: white !important;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* border-radius: 0px !important;
  border-top-right-radius: 0px !important; */
  /* identical to box height */
  /* border-bottom: 1px solid black !important; */

  letter-spacing: -0.03em;

  color: #000000;
}

/* .s-f-tbl2 .ant-table-fixed {
  border-top: 1px solid black;
  border-radius: 0px !important;
} */

.s-f-tbl2 .ant-table-header {
  border-top: 1px solid black;
  border-radius: 0px !important;
}

.s-f-tbl2 .ant-table-tbody > tr > td {
  padding: 6px 6px;
  overflow-wrap: break-word;
}

/* .s-f-tbl2 .ant-table-placeholder {
  border-top: 1px solid black !important;
} */

.s-f-tbl2 td.hide,
.s-f-tbl2 th.hide {
  display: none;
}

/* .s-f-tbl2 td:nth-child(3) {
  border-right: 1px solid #e0e0e0;
} */

.s-f-tag {
  width: 62px;
  height: 24px;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  margin: 0 auto;
}

.s-r-prod-img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.rank .ant-table-row-cell-break-word {
  font-size: 12px;
}

.resize-table-th {
  position: relative;
}

.resize-table-th .table-draggable-handle {
  height: 100% !important;
  bottom: 0;
  left: auto !important;
  right: -5px;
  cursor: col-resize;
  touch-action: none;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scrollable-tbl .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.tbltitle {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.03em;

  color: #000000;
}
</style>

<template>
<div class="s-btn s-btn-type2" @click="onExportExcel()">
   <img class="s-f-filter-default" src="@/assets/images/v4-icon/database.svg"/>엑셀 다운로드 </span>
</div>
</template>

<script>
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { showError, showSuccess } from "../fnc.js";

export default {
  props: {
    header: {
      type: Object,
      default: null
    },
    filename: {
      type: String,
      default: "데이타"
    },
    prefix: {
      type: String,
      default: ""
    },
    data: {
      type: Array,
      default: []
    }
  },
  watch: {
    data() {
      this.initData();
    }
  },
  methods: {
    initData() {
      this.sdata = this.data;
    },
    onExportExcel() {
      var date = new Date();
      var self = this;

      if (this.sdata.length == 0) {
        showError({
          notify: this.$vs.notify,
          msg: "키워드 검색을 한후에 이용 부탁드립니다."
        });
        return false;
      }

      var _main = [];
      var _column = [];
      var _columnClon = [];
      var _dataSwap = [];

      this.header.forEach(item => {
        _columnClon.push({ key: item.key, title: item.etitle, seq: item.seq });
      });

      var wb = XLSX.utils.book_new();

      _columnClon = _columnClon.sort(function(a, b) {
        return a.seq < b.seq ? -1 : a.seq > b.seq ? 1 : 0;
      });

      _columnClon.forEach(item => {
        _column.push(item.title);
      });

      _main.push(_column);

      this.sdata.forEach(item => {
        var _data = [];
        var _idx = 0;
        Object.keys(item).forEach(function eachKey(key) {
          var _t = _columnClon.find(ii => ii.key == key);

          if (_t) {
            _data.push({ key: key, seq: _t.seq, data: item[key] });
          }
        });

        _data = _data.sort(function(a, b) {
          // desc
          return a.seq < b.seq ? -1 : a.seq > b.seq ? 1 : 0;
        });

        _dataSwap = [];
        _data.forEach(ii => {
          _dataSwap.push(ii.data);
        });

        _main.push(_dataSwap);
      });

      var ws1 = XLSX.utils.aoa_to_sheet(_main);

      XLSX.utils.book_append_sheet(wb, ws1, "데이타");

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });

      var _filename = this.prefix
        ? this.prefix
        : "스토어링크-" + self.filename + "_" + date.getTime();

      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          _filename + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    }
  },
  data() {
    return {
      sdata: []
    };
  }
};
</script>

<style>
</style>

<template>
<div>

    <a-modal class="message" :width="360" v-model="visible" :centered="true" :closable="false" :maskClosable="false">
        <div class="close-btn" @click="onClickClose()">닫기</div>
        <div class="contents" v-html="contents">
             
        </div>
         <div class="btn-div" v-if="closeText">
             <div class="btn" @click="onClickBtn(true)"  style="margin-left:5px">
                닫기
            </div>
        </div>
        <div class="btn-div" v-else>
            <div class="btn" @click="onClickBtn(false)" style="margin-right:5px">
                아니오
            </div>
             <div class="btn" @click="onClickBtn(true)"  style="margin-left:5px">
                네
            </div>
        </div>
      
    </a-modal>

</div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: ""
    },
    closeText: {
      type: String,
      default: ""
    },
    contents: {
      type: String,
      default: "Message"
    }
  },
  methods: {
    onClickClose() {
      this.$emit("closed", true);
    },
    onClickBtn(value) {
      this.$emit("clicked", value);
      this.$emit("closed", true);
    }
  },
  data() {
    return {
      sdata: []
    };
  }
};
</script>

<style>
.message .ant-modal-footer {
  display: none;
}

.message .ant-modal-content {
  border-radius: 10px !important;
}

/* .message .ant-modal-content {
  width: 360px !important;
  height: 189px !important;
} */

.message .btn {
  width: 90px;
  height: 40px;
  background: #000000;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.message .btn-div {
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message .close-btn {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: -0.03em;
  cursor: pointer;
  color: #828282;
}
.message .contents {
  margin-top: 21px;
  margin-bottom: 21px;
}
.message .contents p,
.message .contents span,
.message .contents {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
}
</style>
